const SERVER_URL = 'https://api.qingcigame.com';// 测试链接
const WEB_PROFIX = '';
var obj = {
  // 初始化
  init: function () {
    this.common();
    this.swiper();
  },

  common(){
    this.share();
    this.reset();
    this.bind();
    this.wowAni(); // wow动画
  },

  news: function() {
    this.common();
    this.getNews();
  },

  detail: function() {
    this.common();
    this.getDetail();
  },

  share: function () {
    this.onAjax({
      url: `${SERVER_URL}/wechat/jssdk`,
      type: "POST",
      data: {
        uri: window.location.href,
      },
      success: function (json) {
        // 此刻分享的页面
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        var day = today.getDate();
        day = day < 10 ? "0" + day : day;
        var hour = today.getHours();
        hour = hour < 10 ? "0" + hour : hour;
        var minute = today.getMinutes();
        minute = parseInt(minute / 10) * 10;
        minute = minute < 10 ? "00" : minute;
        var file =
          "https://qingcijoy.com/index" + year + "" + month + "" + day + "" + hour + "" + minute + ".html";
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          wx.config(json.data);
          n;
          wx.ready(function () {
            wx.updateAppMessageShareData({
              title: "成都青瓷互动", // 分享标题
              desc: "成都青瓷互动网络有限公司 首页 关于青瓷 商务合作", // 分享描述
              link: file, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: "https://static.qcplay.com/common/qc_s_logo.png", // 分享图标
              success: function () {
                // obj.addShare();
              },
            });
            wx.updateTimelineShareData({
              title: "成都青瓷互动", // 分享标题
              link: file, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: "https://static.qcplay.com/common/qc_s_logo.png", // 分享图标
              success: function () {
                // obj.addShare();
              },
            });
          });
          return;
        } else {
          wx.config(json.data);
          setShareInfo({
            title: "成都青瓷互动", // 分享标题
            summary: "成都青瓷互动网络有限公司 首页 关于青瓷 商务合作", // 分享内容
            pic: "https://static.qcplay.com/common/qc_s_logo.png", // 分享图片
            url: file, // 分享链接
            // 微信权限验证配置信息，若不在微信传播，可忽略
            WXconfig: {
              appId: json.data.appId, // 公众号的唯一标识
              timestamp: json.data.timestamp, // 生成签名的时间戳
              nonceStr: json.data.nonceStr, // 生成签名的随机串
              signature: json.data.signature, // 签名
            },
          });
        }
      },
    });
  },
  // 移动端响应式
  reset: function () {
    var width = 640; //设置默认最大宽度
    var fontSize = 100; //默认字体大小
    var widthProportion = function () {
      var p =
        ((document.body && document.body.clientWidth) ||
          document.getElementsByTagName("html")[0].offsetWidth) / width;
      return p > 1 ? 1 : p < 0.5 ? 0.5 : p;
    };
    var changePage = function () {
      document
        .getElementsByTagName("html")[0]
        .setAttribute(
          "style",
          "font-size:" + widthProportion() * fontSize + "px !important"
        );
    }.bind(this);

    changePage();
    window.addEventListener(
      "resize",
      function () {
        changePage();
      },
      false
    );
  },

  bind: function () {
    const _this = this;
    $(".nav_btn").click(function () {
      $(".mo_nav_btn").removeClass("mo_nav_btn_on");
      $(".nav_list").fadeOut();
      var navMore = $(".pop_nav_warp");
      if (navMore.hasClass("hide")) {
        $(".pop_nav_warp").removeClass("hide");
      } else {
        $(".pop_nav_warp").addClass("hide");
      }
    });

    // 页尾浏览器升级提示
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      $("body")
        .append(
          '<div style="width:100%;height:50px;background:#fff;color:#000;border-bottom:1px	solid #fff;text-align:center;line-height:50px;border-top:1px solid #cfcfcf;font-size:16px;position:fixed;bottom:0;z-index: 9999">您使用的浏览器版本过低，可能会影响到您浏览本页面，建议升级您的浏览器：  <a href="http://www.google.cn/chrome/browser/desktop/" style="padding:15px; background:#c81331; color:#fff;" target="_black">谷歌 Chrome</a></div>'
        )
        .css("padding-bottom", "50px");
    }

    //导航横线位置
    $(".nav-top .cont li").each(function (i, e) {
      if ($(e).hasClass("active")) {
        $(".nav-top .cont i").css(
          "left",
          $(e).index() *
            ($(".nav-top .cont li").width() +
              parseInt($(".nav-top .cont li").css("margin-right")))
        );
      }
    });
    // 信息中心侧边栏
    $(".nav_bar li span").on("click", function(){
      let id = $(this).attr("id");
      $(this).parent().addClass("selected").siblings().removeClass("selected");
      _this.getNews(id);
    })
    $("body")
      //导航
      .on("mouseenter", ".nav-top .cont li,.nav-top .language", function () {
        var self = $(this);
        self.addClass("active").siblings().removeClass("active");
        $(".nav-top .cont i")
          .stop()
          .animate({
            left:
              self.index() *
              ($(".nav-top .cont li").width() +
                parseInt($(".nav-top .cont li").css("margin-right"))),
          });
        testAnim($(".nav-more"), "fadeInDown", "fadeOutUp");
      })
      .on("mouseleave", ".nav", function () {
        testAnim($(".nav-more"), "fadeOutUp", "fadeInDown");
      });

    //补间动画
    function testAnim(obj, active, old) {
      obj.removeClass(old + " animated2").addClass(active + " animated2");
    }

    //向上飘动效果
    function fadeInUpFn() {
      var arr = $(".fadeInUpFn");
      $(window).scroll(function () {
        fn();
      });
      fn();
      function fn() {
        arr.each(function (i, e) {
          $(e).css("opacity", 0);
          var wTop = $(window).scrollTop();
          var objTop = $(e).offset().top;
          if (objTop - wTop < $(window).height() - 200) {
            $(e).addClass("fadeInUp animated");
          }
        });
      }
    }

    $(".mo_nav_btn").click(function () {
      var _this = $(this);
      $(".pop_nav_warp").addClass("hide");
      if (_this.hasClass("mo_nav_btn_on")) {
        _this.removeClass("mo_nav_btn_on");
        $(".nav_list").fadeOut(0);
      } else {
        $(".nav_list").fadeIn(0);
        _this.addClass("mo_nav_btn_on");
      }
    });
  },

  // wow动画
  wowAni: function () {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },

  swiper: function () {
    // 首页顶部轮播图
    var com_swiper_head = new Swiper(".banner1 .swiper-container", {
      resistanceRatio: 0,
      on: {
        init: function () {
          swiperAnimateCache(this);
          swiperAnimate(this);
        },
        slideChangeTransitionEnd: function () {
          swiperAnimate(this);
          $(".banner1_btn ul li")
            .eq(this.realIndex)
            .addClass("active")
            .siblings()
            .removeClass("active");
        },
      },
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      effect: "fade",
    });

    $(".banner1_btn ul li").on("mouseenter", function () {
      $(".banner1_btn ul li")
        .eq($(this).index())
        .addClass("active")
        .siblings()
        .removeClass("active");
      com_swiper_head.slideTo($(this).index());
    });
  },

  getNews: function(sort) {
    const _this = this;
    let url = "";
    if(sort == undefined) {
      sort = "snail";
    }
    switch(sort){
      case "snail": url = `${SERVER_URL}/web/site/article?game_id=39`; break;
      case "td": url = `${SERVER_URL}/web/site/article?game_id=33`; break;
      case "ares": url = `${SERVER_URL}/web/site/article?game_id=29`; break;
      case "wjdmx": url = `${SERVER_URL}/web/site/article?game_id=12`; break;
      case "ygy3": url = `${SERVER_URL}/web/site/article?game_id=18`; break;
      default:
        layer.msg("暂无数据");
        break;
    }
    _this.onAjax({
      url: url,
      type: 'GET',
      success: (result) => {
        _this.renderNewsDom(result.data.articleList);
      },
      fail: err => {
        console.log(err)
      }
    });
  },

  renderNewsDom: function(data) {
    const html = data.reduce((r, item) => {
      const createDate = new Date(item.created_at);
      const year = createDate.getFullYear(), date = createDate.getDate(), month = createDate.getMonth() + 1;
      const articleUrl = item.article_url || `${WEB_PROFIX}/news_detail.html?id=${item.id}`;
      const target = item.article_url ? "_blank" : "";
      r = `${r}
      <a class="news_li" href="${articleUrl}" target="${target}" id=${item.id}>
        <div class="news_title">${item.article_title}</div>
        <div class="created_time">${year}-${month > 9 ? month : '0' + month}-${date > 9? date: '0' + date}</div>
      </a>`;
      return r;
    }, '');
    $(".news_box").html(html);
  },

  getDetail: function() {
    let id = window.location.href;
    // 截取id字段
    let index = id.lastIndexOf("\=");
    id = id.substring(index+1,id.length);
    const _this = this;
    this.onAjax({
      url: `${SERVER_URL}/web/site/article/${id}`,
      type: 'GET',
      success: (result) => {
        
        _this.renderDetail(result.data.oneList[0]);
      },
    });
  },

  renderDetail: function(info) {
    const createDate = new Date(info.created_at);
    const year = createDate.getFullYear(), date = createDate.getDate(), month = createDate.getMonth() + 1;
    $("#bread_crumb_title").text(info.article_title);
    $(".time").html(`${year}-${month > 9 ? month : '0' + month}-${date > 9? date: '0' + date}`);
    $(".article-title").html(info.article_title);
    $(".article-content").html(info.article_content);
    info.author ? $(".author").html("作者: "+info.author) : "";
    info.address ? $(".address").html("地址: "+info.address) : "";
    info.post_code ? $(".post_code").html("邮编: "+info.post_code) : "";
    info.email ? $(".email").html("邮箱: "+info.email) : "";
    info.phone ? $(".phone").html("联系: "+info.phone) : "";
  },

  // 简易封装常用ajax
	onAjax: function(options) {
		const { url, type, success, error, data, async } = options;
		$.ajax({
      url: url,
      type: type,
			data: data,
      async: async,
			success: function(json) {
				if (json.code !== 200) {
					var message = json.message || "服务器错误";
					layer.msg(message);
					return false;
				}
				success && success(json);
			},
			error: function(err) {
				layer.msg("服务器错误");
				error && error(err);
			}
	  });
	},
};
